import React, { useState, useEffect } from 'react';
import { Button, FormControlLabel, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { getUserData, changePassword } from '../../api/Api';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { deleteUser } from "../../api/Api";

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #000',
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    padding: "20px"
};


interface PropsSettings {
    token: string;
    setToken: React.Dispatch<React.SetStateAction<any>>;
}
const Settings = (props: PropsSettings) => {
    const token = props.token;
    const setToken = props.setToken;

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    
    const [openDelete, setOpenDelete] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getUserData(token);
            setUsername(data.username);
            setEmail(data.email);
        }
        
        fetchData();
    }, []);
    
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const old_password = `${data.get("oldPassword")}`;
        const new_password = `${data.get("newPassword")}`;
        const confirm_new_password = `${data.get("confirmNewPassword")}`;
        const response = await changePassword(
            token,
            { old_password, new_password, confirm_new_password }
        );
        setMessage(response.msg);
    };
    
    const handleDelete = async (e: any) => {
        e.preventDefault();
        const response = await deleteUser(token);
        setToken("");
    }

    return (
        <Container sx={{
            width: "90%",
            ladding: "0px",
            marginTop: "100px",
            marginBottom: "30px"
          }} disableGutters maxWidth={false}>
            <Box sx={{height: "150px"}}>
                <h1>Settings</h1>
                { username && email && <>
                <Box style={{marginBottom: "50px"}}>
                    <h3>username: {username}</h3>
                    <h3>email: {email}</h3>
                </Box>
                </> }
                {!username && !email && <CircularProgress sx={{marginTop: "30px"}} size={60} /> }
            </Box>
            <h2>Change Password</h2>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: "50%"}}>
                <TextField
                    sx={{input: { color: '#fff' }, "& label":{ color: '#fff'}}}
                    margin="normal"
                    required
                    fullWidth
                    id="oldPassword"
                    label="Old Password"
                    name="oldPassword"
                    autoFocus
                    type="password"
                />
                <TextField
                    sx={{input: { color: '#fff' }, "& label":{ color: '#fff'}}}
                    margin="normal"
                    required
                    fullWidth
                    id="newPassword"
                    label="New Password"
                    name="newPassword"
                    type="password"
                />
                <TextField
                    sx={{input: { color: '#fff' }, "& label":{ color: '#fff'}}}
                    margin="normal"
                    required
                    fullWidth
                    name="confirmNewPassword"
                    id="confirmNewPassword"
                    label="Confirm New Password"
                    type="password"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2}}
                >
                    Update
                </Button>
                {message}
            </Box>
            <h2>Delete User</h2>
            <Box sx={{ mt: 1, width: "50%"}}>
                <Button
                    fullWidth
                    onClick={() => setOpenDelete(true)}
                    variant="contained"
                    color="error"
                    sx={{ mt: 3, mb: 2}}
                >
                    Delete User 
                </Button>
            </Box>
            
            <Modal
              sx={modalStyle}
              open={openDelete}
              onClose={() => setOpenDelete(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{padding: "0px"}}
            >
              <Box style={{padding: "0px"}}>
                <Typography id="modal-modal-title" variant="h4" component="h1" style={{borderBottom: "solid 1px rgba(255, 255, 255, .09)", padding: "20px", backgroundColor: "#000"}}>
                    User Deletion
                </Typography>
                <Typography id="modal-modal-description" sx={{ padding: "20px"}}>
                    The following data will be deleted:
                    <ul>
                        <li>All user data</li>
                        <li>All solved challenges</li>
                        <li>All points</li>
                    </ul>
                    <h2>Are you sure you want to delete user account?</h2>
                    <Grid container spacing={2} style={{marginTop: "20px"}}>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => setOpenDelete(false)}
                            >
                                No, abort!
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                               fullWidth
                               color="error"
                               variant="contained"
                               onClick={handleDelete}
                            >       
                                Yes! Delete!
                            </Button>
                        </Grid>
                    </Grid>
                </Typography>
              </Box>
            </Modal>
        </Container>
    );
}

export default Settings;