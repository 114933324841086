import React, { useState, useEffect } from 'react';
import './App.css';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import Confirm from './components/Register/Confirm';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ResetPassword from './components/ForgotPassword/ResetPassword';
import TopBar from './components/TopBar/TopBar';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import Dashboard from './components/Dashboard/Dashboard';
import Academy from './components/Academy/Academy';
import Challenges from './components/Academy/Challenges';
import Settings from './components/Settings/Settings';
import Challenge from './components/Challenge/Challenge';
import Leaderboard from './components/Leaderboard/Leaderboard';
import Missions from './components/Missions/Missions';
import Profile from './components/Profile/Profile';

import AdminChallenges from './components/Admin/AdminChallenges';
import AdminAddChallenge from './components/Admin/AdminAddChallenge';
import AdminUpdateChallenge from "./components/Admin/AdminUpdateChallenge";
import AdminUsers from "./components/Admin/AdminUsers";

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import ScrollToTop from "./utils/ScrollTop";

import { getUserData, logout } from "./api/Api";

import ReactGA from "react-ga4";


const TRACKING_ID = "G-FES4RX8HPV";
ReactGA.initialize(TRACKING_ID);

function useToken() {
  function getToken() {
    const tokenString = localStorage.getItem("token");
    if(tokenString) {
      const userToken = JSON.parse(tokenString);
      return userToken;
    }
    return "";
  }
  
  const [token, setToken] = useState(getToken());
  const saveToken = (userToken: any) => {
    localStorage.setItem("token", JSON.stringify(userToken));
    setToken(userToken);
  }
  
  return {
    token,
    setToken: saveToken
  }
}

function App() {
  const { token, setToken } = useToken();
  const [username, setUsername] = useState("");
  const [admin, setAdmin] = useState(false);
  const [smallMenu, setSmallMenu] = useState(false);


  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    const updateUser = async () => {
        const data = await getUserData(token);
        if(data) {
            setUsername(data.username);
            setAdmin(data.admin);
            if(data.access_token !== "" && token != data.access_token) {
              setToken(data.access_token);
            }
        } else {
            setToken("");
        }
    }
    
    const interval = setInterval(updateUser, 20 * 60 * 1000); // 20 minutes

    if(token) updateUser();
    
    return () => clearInterval(interval);
  }, [token]);

/*
  if(!token) {
      return (
        <BrowserRouter>
          <Routes>
            <Route path="/confirm/:token" element={<Confirm />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/register" element={<Register />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/login" element={<Login setToken={setToken} />} />
            <Route path="*" element={<Navigate to="/login" />}/>
          </Routes> 
        </BrowserRouter>
      )
  }
  */

  return (
    <>
      <BrowserRouter>
      <TopBar token={token} username={username} setToken={setToken} admin={admin} />
      <Container disableGutters sx={{ladding: "0px"}} maxWidth={false}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <ScrollToTop />
          <Routes>
            { token == null ||token == "" && <>
              <Route path="/confirm/:token" element={<Confirm />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login setToken={setToken} />} />
            </> }

            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            <Route path="/" element={<Home token={token} />} />
            <Route path="/dashboard" element={<Dashboard token={token} />} />
            <Route path="/academy" element={<Academy token={token} />} />
            <Route path="/academy/:category" element={<Challenges token={token} />} />
            <Route path="/academy/challenge/:id" element={<Challenge token={token}/>} />

            <Route path="/missions" element={<Missions token={token} /> } />
            <Route path="/missions/:id" element={<Challenge token={token}/>} />

            <Route path='/leaderboard' element={<Leaderboard token={token} />} />
            <Route path='/profile/:username' element={<Profile />} />

            { token != "" && <Route path='/settings' element={<Settings token={token} setToken={setToken} />} /> }

            { admin && <Route path='/admin/challenges' element={<AdminChallenges token={token} />} /> }
            { admin && <Route path='/admin/challenges/add' element={<AdminAddChallenge token={token} />} /> }
            { admin && <Route path='/admin/challenge/:id' element={<AdminUpdateChallenge token={token} />} /> }
            { admin && <Route path='/admin/users' element={<AdminUsers token={token} />} /> }
            <Route path="*" element={<Navigate to="/" />}/>
          </Routes>
        </Grid>
      </Grid>
      </Container>
      </BrowserRouter>
    </>
  );
}

export default App;
