import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import { IconButton } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import { getUserData, logout } from "../../api/Api";
import { NavLink, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard'
import AccountBalanceSharpIcon from '@mui/icons-material/AccountBalanceSharp';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import MapIcon from '@mui/icons-material/Map'
import { useNavigate } from "react-router-dom";
import TwitterIcon from '@mui/icons-material/Twitter';


const sxLink = {
    textDecoration: "none",
    fontSize: "16px",
    fontWeight: "normal",
    alignItems: "center",
    textAlign: "center",
    color: "#dde0e4",
    padding: "15px 30px 15px 30px",
    
    "&:hover": {
        color: "#fff",
        backgroundColor: "rgba(255, 255, 255, 0.06)",
        cursor: "pointer"
    }
};

const sxLinkActive = {
    ...sxLink,
    backgroundColor: "rgba(255, 255, 255, 0.06)"
};

interface PropsTopBar {
    token: string;
    username: string;
    setToken: React.Dispatch<React.SetStateAction<any>>;
    admin: boolean;
}
const TopBar = (props: PropsTopBar) => {
    const location = useLocation();
    const navigate = useNavigate();
    const token = props.token;
    const setToken = props.setToken;
    const username = props.username;
    const admin = props.admin

    const handleLogout = async () => {
        logout(token);
        setToken("");
        navigate("/");
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }; 

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <AppBar
            sx={{
                backgroundColor: location.pathname == "/" ? "rgba(0,0,0,0)" : "#13151a",
                borderBottom: location.pathname == "/" ? "solid 0px" : "solid 1px rgba(255, 255, 255, .08)",
                boxShadow: location.pathname == "/" ? "none" : "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
            }}>
            <Container maxWidth={false} sx={{"width": "90%"}}>
            <Toolbar disableGutters>

            <Box sx={{flexGrow: 1, display: {xs: "none", md: "flex"}}}>
            <Box sx={{
                verticalAlign: "middle"
            }}>
                <Typography
                    variant="h5"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                        color: "inherit",
                        verticalAlign: "middle",
                        textDecoration: "none",
                        fontWeight: "bold"
                    }}
                >
                    <NavLink style={{color: "#fff", textDecoration: "none"}} to={"/"}>
                    <Box sx={{
                        alignItems: "center",
                        textAlign: "center",
                        marginTop: "20px",
                        marginRight: "30px",
                    }}>
                                WEB<span style={{color: "#80b7db"}}>3</span>PWN
                        </Box>
                    </NavLink>
                </Typography>
            </Box>
                    <NavLink style={{textDecoration: "none"}} to={"/dashboard"}>
                        <Box sx={
                            location.pathname == "/dashboard" ? sxLinkActive : sxLink }>
                            <DashboardIcon fontSize="small" />
                            <div style={{}}>Dashboard</div>
                        </Box>
                    </NavLink>
                    <NavLink style={{textDecoration: "none"}} to={"/academy"}>
                        <Box sx={
                            location.pathname == "/academy" ||
                            location.pathname.startsWith("/academy/") ? sxLinkActive : sxLink }>
                            <AccountBalanceSharpIcon fontSize="small" />
                            <div style={{}}>Academy</div>
                        </Box>
                    </NavLink>
                    <NavLink style={{textDecoration: "none"}} to={"/missions"}>
                        <Box sx={
                            location.pathname == "/missions" ||
                            location.pathname.startsWith("/missions/") ? sxLinkActive : sxLink }>
                            <MapIcon fontSize="small" />
                            <div style={{}}>Missions</div>
                        </Box>
                    </NavLink>
                    <NavLink style={{textDecoration: "none"}} to={"/leaderboard"}>
                        <Box sx={location.pathname == "/leaderboard" ? sxLinkActive : sxLink }>
                            <LeaderboardIcon fontSize="small" />
                            <div style={{}}>Leaderboard</div>
                        </Box>
                    </NavLink>
                    {admin && <>
                        <NavLink style={{textDecoration: "none"}} to={"/admin/challenges"}>
                                <Box sx={
                                    location.pathname == "/admin/challenges" ||
                                    location.pathname.startsWith("/admin/challenges/") ||
                                    location.pathname.startsWith("/admin/challenge/") ? sxLinkActive : sxLink }>
                                    <AssignmentIcon fontSize="small" />
                                    <div>Challenges</div>
                                </Box>
                            </NavLink>
                            <NavLink style={{textDecoration: "none"}} to={"/admin/users"}>
                                <Box sx={location.pathname == "/admin/users" ? sxLinkActive : sxLink }>
                                    <PeopleIcon fontSize="small" />
                                    <div>Users</div>
                                </Box>
                            </NavLink>
                    </>
                    } 
                    
                    <Link to={"https://discord.gg/wZge4Rz8nT"} style={{textDecoration: "none"}}>
                        <Box sx={sxLink}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="m22 24l-5.25-5l.63 2H4.5A2.5 2.5 0 0 1 2 18.5v-15A2.5 2.5 0 0 1 4.5 1h15A2.5 2.5 0 0 1 22 3.5V24M12 6.8c-2.68 0-4.56 1.15-4.56 1.15c1.03-.92 2.83-1.45 2.83-1.45l-.17-.17c-1.69.03-3.22 1.2-3.22 1.2c-1.72 3.59-1.61 6.69-1.61 6.69c1.4 1.81 3.48 1.68 3.48 1.68l.71-.9c-1.25-.27-2.04-1.38-2.04-1.38S9.3 14.9 12 14.9s4.58-1.28 4.58-1.28s-.79 1.11-2.04 1.38l.71.9s2.08.13 3.48-1.68c0 0 .11-3.1-1.61-6.69c0 0-1.53-1.17-3.22-1.2l-.17.17s1.8.53 2.83 1.45c0 0-1.88-1.15-4.56-1.15m-2.07 3.79c.65 0 1.18.57 1.17 1.27c0 .69-.52 1.27-1.17 1.27c-.64 0-1.16-.58-1.16-1.27c0-.7.51-1.27 1.16-1.27m4.17 0c.65 0 1.17.57 1.17 1.27c0 .69-.52 1.27-1.17 1.27c-.64 0-1.16-.58-1.16-1.27c0-.7.51-1.27 1.16-1.27Z"/></svg>
                            <div>Discord</div>
                        </Box>
                    </Link>
                    <Link to={"https://twitter.com/web3pwn"} style={{textDecoration: "none"}}>
                        <Box sx={sxLink}>
                            <TwitterIcon fontSize="small" />
                            <div>Twitter</div>
                        </Box>
                    </Link>
            </Box>
            { token == "" && <Box>
                <NavLink style={{
                  textDecoration: "none",
                  color: "#ccc",
                  fontSize: "18px",
                  padding: "10px"
                }} to={"/login"}>Sign In</NavLink>
                <NavLink style={{
                  textDecoration: "none",
                  color: "#fff",
                  backgroundColor: "rgb(25, 118, 210)",
                  padding: "10px 20px 10px 20px",
                  fontSize: "18px"
                }} to={"/register"}>Get Started</NavLink>
            </Box> }
            { token != "" && <Box sx={{flexGrow: 0}}>
                <IconButton
                    size="large"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu} 
                    sx={{
                        fontSize: "19px",
                        color: "#fff"
                    }}
                >
                    {username}
                    <AccountCircle sx={{marginLeft: "10px", color: "#fff"}}/>
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    sx={
                        { mt: "30px", "& .MuiMenu-paper": 
                          { backgroundColor: "#13151a", }, 
                        }
                    }
                >
                    <MenuItem onClick={handleClose}>
                        <Link to={"/settings"} style={{color: "#fff", textDecoration: "none"}}>
                            Settings
                        </Link>
                    </MenuItem>
                    <MenuItem onClick={handleLogout} style={{color: "#fff", textDecoration: "none"}}>Logout</MenuItem>
                </Menu>
            </Box> }
            </Toolbar>
            </Container>
        </AppBar>
    )
}

export default TopBar 