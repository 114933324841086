import React, {useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AccountBalanceSharpIcon from '@mui/icons-material/AccountBalanceSharp';
import Slider from '@mui/material/Slider';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MapIcon from '@mui/icons-material/Map';
import Container from '@mui/material/Container';
import { NavLink, useLocation } from 'react-router-dom';

import { Link, useParams } from 'react-router-dom';
import { Difficulty } from "../../shared/Difficulty";

import { getUserStats, getProfileStats, getRecentChallenges, getLastSolved } from "../../api/Api";
import CategoryStat from '../Dashboard/CategoryStat';
import { SettingsApplicationsSharp } from '@mui/icons-material';
import { categoryToText } from "../../utils/Category";
import { NumberLiteralType } from 'typescript';
import { isBuffer } from 'util';
import { useNavigate } from "react-router-dom";


const ranks = [
  { value: 0, label: "Novice", },
  { value: 20, label: "Explorer", },
  { value: 40, label: "Master", },
  { value: 60, label: "Hacker", },
  { value: 80, label: "Sentinel"},
  { value: 100, label: "Overlord", },
]

const rankToText = (rank: number) => {
    if(rank < 20) return ranks[0].label;
    else if(rank < 40) return ranks[1].label;
    else if(rank < 60) return ranks[2].label;
    else if(rank < 80) return ranks[3].label;
    else if(rank < 100) return ranks[4].label;
    else return ranks[5].label;
};

interface Challenge {
    id: string;
    name: string; 
    type: number;
    category: string;
    difficulty: number;
    points: number;
    solved: boolean;
}

interface Stats {
  challenges: number;
  solved: number;
}

interface UserStats {
  username: string;
  points: number;
  total: number; 
  stats: Stats;
}

interface LastSolved {
  user_id: string;
  username: string; 
  challenge_id: string;
  challenge_name: string;
  challenge_difficulty: number;
  challenge_type: number;
  // solved_at: string;
}

interface PropsDashboard {
    username: string;
}

const Profile = () => {
    const { username } = useParams();
    const navigate = useNavigate();

    const [userStats, setUserStats] = useState<UserStats>();

    const [introduction, setIntroduction] = useState<Stats>();
    const [accessControl, setAccessControl] = useState<Stats>();
    const [reentrancy, setReentrancy] = useState<Stats>();
    const [txOrigin, setTxOrigin] = useState<Stats>();
    const [gasOptimization, setGasOptimization] = useState<Stats>();
    const [dos, setDos] = useState<Stats>();
    const [signatures, setSignatures] = useState<Stats>();
    const [upgradeability, setUpgradeability] = useState<Stats>();
    const [randomness, setRandomness] = useState<Stats>();
    const [missions, setMissions] = useState<Stats>();
    const [rank, setRank] = useState("Rank");

    const [challenges, setChallenges] = useState<Challenge[]>([]);
    const [lastSolved, setLastSolved] = useState<LastSolved[]>([]);

    useEffect(() => {        
        const fetchProfile = async () => {
          if(!username) return;

          const response = await getProfileStats(username);

          if(response) {
            if(response.status == 404) {
                navigate("/");
                return;
            }

            setUserStats(response.data);
            const tmpRank = rankToText(response.data.points * 100 / response.data.total);
            setRank(tmpRank);

            const data = response.data;

            for(let i=0; i<data.stats.length; i++) {
              if(data.stats[i].category == "introduction") {
                  setIntroduction(data.stats[i]);
              }
              else if(data.stats[i].category == "access-control") {
                  setAccessControl(data.stats[i]);
              }
              else if(data.stats[i].category == "reentrancy") {
                  setReentrancy(data.stats[i]);
              }
              else if(data.stats[i].category == "denial-of-service") {
                  setDos(data.stats[i]);
              }
              else if(data.stats[i].category == "randomness") {
                  setRandomness(data.stats[i]);
              }
              else if(data.stats[i].category == "signatures") {
                  setSignatures(data.stats[i]);
              }
              else if(data.stats[i].category == "upgradeability") {
                  setUpgradeability(data.stats[i]);
              }
              else if(data.stats[i].category == "txorigin") {
                  setTxOrigin(data.stats[i]);
              }
              else if(data.stats[i].category == "gas-optimization") {
                  setGasOptimization(data.stats[i]);
              }
              else if(data.stats[i].category == "missions") {
                  setMissions(data.stats[i]);
              }
          }
          }
        }

        fetchProfile();
    }, []);

    return (
        <Container sx={{
          width: "90%",
          ladding: "0px",
          marginTop: "100px",
          marginBottom: "30px"
        }} disableGutters maxWidth={false}>
            <Box sx={{border: "1px solid rgb(51, 51, 51)", padding: "0px 0px 0px 0px"}}>

            <Grid container spacing={2} sx={{
              padding: "30px 60px 30px 60px"
            }}>
              <Grid item xs={6}>
                {introduction && <CategoryStat name="Introduction" link={"/academy/introduction"} solved={introduction.solved} challenges={introduction.challenges} /> }
                {accessControl && <CategoryStat name="Access Control" link={"/academy/access-control"} solved={accessControl.solved} challenges={accessControl.challenges} /> }
                {reentrancy && <CategoryStat name="Reentrancy" link={"/academy/reentrancy"} solved={reentrancy.solved} challenges={reentrancy.challenges} /> }
                {randomness && <CategoryStat name="Randomness" link={"/academy/randomness"} solved={randomness.solved} challenges={randomness.challenges} /> }
                {dos && <CategoryStat name="Denial of Service" link={"/academy/denial-of-service"} solved={dos.solved} challenges={dos.challenges} /> }
                {signatures && <CategoryStat name="Signatures" link={"/academy/signatures"} solved={signatures.solved} challenges={signatures.challenges} /> }
                {upgradeability && <CategoryStat name="Upgradeability" link={"/academy/upgradeability"} solved={upgradeability.solved} challenges={upgradeability.challenges} /> }
                {txOrigin && <CategoryStat name="TX Origin" link={"/academy/txorigin"} solved={txOrigin.solved} challenges={txOrigin.challenges} /> }
                {gasOptimization && <CategoryStat name="Gas Optimization" link={"/academy/gas-optimization"} solved={gasOptimization.solved} challenges={gasOptimization.challenges} /> }
                {missions && <CategoryStat name="Missions" link={"/missions"} solved={missions.solved} challenges={missions.challenges} /> }
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={6}>
                    <h2>{userStats && userStats.username}</h2>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{marginLeft: "auto", width: "200px", height: "200px", textAlign: "center"}}>
                      <img style={{
                        width: "200px",
                        overflow: "hidden",
                        borderRadius: "50%" 
                      }} src={`/images/ranks/${rank.toLowerCase()}.png`} />
                      <h4 style={{padding: "0px", margin: "0px"}}>{ rank }</h4>
                    </Box>
                  </Grid>
                </Grid>
                <Box>
                  <h3 style={{margin: "0px"}}>Progress</h3>
                  <Slider
                    sx={{
                      "& .MuiSlider-mark": {
                          width: "15px",
                          height: "15px",
                          backgroundColor: "#1976d2",
                          borderRadius: "50%",
                          opacity: "1.0"
                      },
                    "& .MuiSlider-markLabel": {
                      color: "#fff"
                      },
                      '& .MuiSlider-thumb': {
                        color: "#1976d2",
                    },
                    '& .MuiSlider-track': {
                        color: "#1976d2"
                    },
                    '& .MuiSlider-rail': {
                        color: "#1976d2"
                    },
                    }}
                    aria-label="Always visible"
                    value={userStats ? userStats.points * 100 / userStats.total : 0}
                    valueLabelFormat={rankToText}
                    step={20}
                    marks={ranks}
                    disabled={true}
                  />
                </Box>
              </Grid>
            </Grid>
            </Box>
        </Container>
    );
}

export default Profile;